import axios from 'axios';

// export const baseURL = 'http://localhost:5000';
export const baseURL = 'https://server.mudahindonesia.com';

export const baseURLV2 = process.env.REACT_APP_BACKEND_URL;
// export const baseURLV2 = 'https://server.mudahindonesia.com';

export default axios.create({
  baseURL: baseURL,
});

export const axiosV2 = axios.create({
  baseURL: baseURLV2,
});
