import React, { useState } from 'react';

// mui components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Checkbox } from '@mui/material';

// import tnc modals
import TncSk from './tnc-modal/TncSk';
import TncBtj from './tnc-modal/TncBtj';
import TncKpi from './tnc-modal/TncKpi';

const RegisterTnc2 = ({ handleNext }) => {
  const [checkSK, setCheckSK] = useState(false);
  const [checkBTJ, setCheckBTJ] = useState(false);
  const [checkKPI, setCheckKPI] = useState(false);

  const [tncSkModalOpen, setTncSkModalOpen] = useState(false);
  const [tncBtjModalOpen, setTncBtjModalOpen] = useState(false);
  const [tncKpiModalOpen, setTncKpiModalOpen] = useState(false);

  const handleTncSkModalClose = () => setTncSkModalOpen(false);
  const handleTncBtjModalClose = () => setTncBtjModalOpen(false);
  const handleTncKpiModalClose = () => setTncKpiModalOpen(false);
  const handleCloseAllTncModals = () => {
    handleTncSkModalClose();
    handleTncBtjModalClose();
    handleTncKpiModalClose();
  };

  const formHandler = async (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <>
      <Box component="form" onSubmit={formHandler}>
        <Grid container justifyContent="center">
          <Grid
            container
            item
            alignItems="center"
            wrap="nowrap"
            xs={12}
            sx={{ mt: 4 }}
          >
            <Grid item>
              <Checkbox
                checked={checkSK}
                onChange={(e) => setCheckSK(e.target.checked)}
              ></Checkbox>
            </Grid>
            <Grid item>
              <Box sx={{ flexDirection: 'column' }}>
                <Typography
                  variant="body2"
                  sx={{ color: 'primary.mudahBlack' }}
                >
                  Setuju terhadap{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Syarat dan Ketentuan
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'primary.mudahDarkGrey' }}
                >
                  Pelajari lanjut Syarat dan Ketentuan{' '}
                  <Box
                    component="span"
                    onClick={(e) => setTncSkModalOpen(true)}
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      color: 'primary.main',
                      textDecoration: 'underline',
                    }}
                  >
                    di sini
                  </Box>
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            wrap="nowrap"
            xs={12}
            sx={{ mt: 2 }}
          >
            <Grid item>
              <Checkbox
                checked={checkBTJ}
                onChange={(e) => setCheckBTJ(e.target.checked)}
              ></Checkbox>
            </Grid>

            <Grid item>
              <Box sx={{ flexDirection: 'column' }}>
                <Typography
                  variant="body2"
                  sx={{ color: 'primary.mudahBlack' }}
                >
                  Setuju terhadap{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Batasan Tanggung Jawab
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'primary.mudahDarkGrey' }}
                >
                  Pelajari lanjut Batasan Tanggung Jawab{' '}
                  <Box
                    component="span"
                    onClick={(e) => setTncBtjModalOpen(true)}
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      color: 'primary.main',
                      textDecoration: 'underline',
                    }}
                  >
                    di sini
                  </Box>
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            wrap="nowrap"
            xs={12}
            sx={{ mt: 2 }}
          >
            <Grid item>
              <Checkbox
                checked={checkKPI}
                onChange={(e) => setCheckKPI(e.target.checked)}
              ></Checkbox>
            </Grid>

            <Grid item>
              <Box sx={{ flexDirection: 'column' }}>
                <Typography
                  variant="body2"
                  sx={{ color: 'primary.mudahBlack' }}
                >
                  Setuju terhadap{' '}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Keamanan dan Perlindungan Informasi
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'primary.mudahDarkGrey' }}
                >
                  Pelajari lanjut Keamanan dan Perlindungan Informasi{' '}
                  <Box
                    component="span"
                    onClick={(e) => setTncKpiModalOpen(true)}
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      color: 'primary.main',
                      textDecoration: 'underline',
                    }}
                  >
                    di sini
                  </Box>
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{ height: '50px', mt: 4, borderRadius: '25px' }}
              disabled={!checkSK || !checkBTJ || !checkKPI}
            >
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Setujui Semua
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <TncSk
        tncModalOpen={tncSkModalOpen}
        handleTncModalClose={handleTncSkModalClose}
        handleCloseAllTncModals={handleCloseAllTncModals}
      />

      <TncBtj
        tncModalOpen={tncBtjModalOpen}
        handleTncModalClose={handleTncBtjModalClose}
        handleCloseAllTncModals={handleCloseAllTncModals}
      />

      <TncKpi
        tncModalOpen={tncKpiModalOpen}
        handleTncModalClose={handleTncKpiModalClose}
        handleCloseAllTncModals={handleCloseAllTncModals}
      />
    </>
  );
};

export default RegisterTnc2;
