import React, { useState } from 'react';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';

// import backend api
import { baseURLV2 } from '../../api';

// import static assets
import '../css/MainContainer.css';

// import components
import MudahCopyright from '../components/MudahCopyright';

// import mui components
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import page components
import VerifBlock from '../components/auth/VerifBlock';
import QrisCopywriting from '../components/footnotes/QrisCopy';

// import images
import QRIS from '../../assets/qris.png';
import GPN from '../../assets/gpn.png';
import DownloadIcon from '../../assets/icon-download.png';

// hooks
import { useCreateDynamicQris } from '../../hooks/services/qris';

const Qris = () => {
  const [showDynamicQris, setShowDynamicQris] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const [dynamicQrisLoading, setDynamicQrisLoading] = useState(true);
  const [dynamicQrisImage, setDynamicQrisImage] = useState('');

  const [dynamicQRISValue, setDynamicQRISValue] = useState(0);
  // const [dynamicQRISNotes, setDynamicQRISNotes] = useState('');
  const [dynamicQRISName, setDynamicQRISName] = useState('');
  const [dynamicQRISNumber, setDynamicQRISNumber] = useState('');

  const staticQrisPNGURL = `${baseURLV2}/teras/v1/qris/static/png`;
  const staticQrisPDFURL = `${baseURLV2}/teras/v1/qris/static/pdf`;

  const { mutateAsync: createDynamicQris } = useCreateDynamicQris();

  // Transaction Handler
  const handleShowDynamicQris = async (e) => {
    e.preventDefault();

    if (dynamicQRISValue < 1) {
      alert('Nominal transaksi tidak boleh kosong');
      return;
    }

    if (dynamicQRISName === '') {
      alert('Nama pelanggan tidak boleh kosong');
      return;
    }

    setShowDynamicQris(true);
    setDynamicQrisLoading(true);

    const data = await createDynamicQris({
      amount: dynamicQRISValue,
      customerName: dynamicQRISName,
      customerPhoneNumber: dynamicQRISNumber,
    });

    if (data.success) {
      let qrisImage = `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${data.qris_string}`;
      setDynamicQrisImage(qrisImage);
    }
    else {
      alert('Gagal membuat QRIS dinamis');
      setShowDynamicQris(false);
    }

    setDynamicQrisLoading(false);
  };

  const handleHideDynamicQris = (e, reason) => {
    if (reason && reason === 'backdropClick') return;

    setDynamicQRISValue(0);
    // setDynamicQRISNotes('');
    setDynamicQRISName('');
    setDynamicQRISNumber('');
    setShowDynamicQris(false);
  };

  const handleFinishDynamicQris = () => {
    setShowDynamicQris(false);
  };

  const handleDownloadModalClose = () => {
    setDownloadModalOpen(false);
  };

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="QRIS" />
        <VerifBlock>
          <Paper
            elevation={4}
            sx={{
              px: { xs: '15px', md: '35px' },
              py: { xs: '20px', md: '35px' },
              mt: 1,
            }}
          >
            <Grid container spacing={4}>
              {/* QRIS STATIS */}
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Typography variant="h2" fontWeight="bold" sx={{ mb: 2 }}>
                    QRIS Statis
                  </Typography>
                  <>
                    <Grid item container justifyContent="center" xs={12}>
                      <Box
                        component="img"
                        src={staticQrisPNGURL}
                        sx={{
                          width: { xs: '100%', md: 315 },
                          height: 'auto',
                          mx: 'auto',
                          border: '1px solid grey',
                          borderRadius: 4,
                        }}
                      ></Box>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      sx={{
                        maxWidth: { xs: 250, md: 365 },
                        mx: 'auto',
                        mt: 4,
                      }}
                    >
                      {/* <Grid item xs={12} md={6} container  */}
                      <Grid item xs={12} container justifyContent="center">
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            // width: '150px',
                            my: { xs: 1, md: 0 },
                            borderRadius: '25px',
                          }}
                          onClick={() => setDownloadModalOpen(true)}
                        >
                          Download QRIS
                        </Button>
                      </Grid>
                      {/* <Grid item xs={12} md={6} container justifyContent="center">
                          <Button
                            component="a"
                            target="_blank"
                            href="https://mudahindonesia.com/cetak-qris/"
                            variant="outlined"
                            sx={{
                              color: 'primary.mudahBlack',
                              borderColor: 'primary.mudahBlack',
                              borderRadius: '25px',
                              width: '150px',
                              my: { xs: 1, md: 0 },
                            }}
                          >
                            Cetak QRIS
                          </Button>
                        </Grid> */}
                    </Grid>
                  </>
                </Grid>
              </Grid>

              {/* QRIS DINAMIS */}
              <Grid item xs={12} md={6}>
                <Typography variant="h2" fontWeight="bold" sx={{ mb: 2 }}>
                  QRIS Dinamis
                </Typography>
                <Box
                  component="form"
                  sx={{
                    mx: 'auto',
                    p: 3,
                    border: '1px solid grey',
                    borderRadius: '10px',
                    maxWidth: { xs: 350, md: 450 },
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: 'primary.mudahDarkGrey' }}
                  >
                    Transaksi
                  </Typography>
                  <TextField
                    required
                    id="tx-value"
                    name="transaction value"
                    label="Nominal Transaksi"
                    placeholder="Contoh: 25000, minimal 10000"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp</InputAdornment>
                      ),
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fullWidth
                    sx={{
                      mt: 2,
                    }}
                    value={dynamicQRISValue.toString()}
                    onChange={(e) => {
                      setDynamicQRISValue(parseInt(e.target.value) || 0);
                    }}
                  />
                  {/* <TextField
                    id="tx-notes"
                    name="transaction notes"
                    label="Deskripsi Transaksi (opsional)"
                    placeholder="Contoh: Ayam Goreng x 1"
                    fullWidth
                    sx={{
                      mt: 2,
                    }}
                    value={dynamicQRISNotes}
                    onChange={(e) => {
                      setDynamicQRISNotes(e.target.value);
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'primary.mudahDarkGrey',
                      },
                    }}
                  /> */}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ mt: 4, color: 'primary.mudahDarkGrey' }}
                  >
                    Identitas Pelanggan
                  </Typography>
                  <TextField
                    required
                    id="cust-name"
                    name="customer name"
                    label="Nama Pelanggan"
                    placeholder="Contoh: Ali Akbar"
                    fullWidth
                    sx={{
                      mt: 2,
                    }}
                    value={dynamicQRISName}
                    onChange={(e) => {
                      setDynamicQRISName(e.target.value);
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'primary.mudahDarkGrey',
                      },
                    }}
                  />
                  <TextField
                    id="cust-number"
                    name="customer number"
                    label="No HP Pelanggan (opsional)"
                    placeholder="Contoh: 0812xxxxxxx"
                    fullWidth
                    sx={{
                      mt: 2,
                    }}
                    value={dynamicQRISNumber}
                    onChange={(e) => {
                      setDynamicQRISNumber(e.target.value);
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'primary.mudahDarkGrey',
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ height: '50px', borderRadius: '25px', mt: 4 }}
                    id="create-dynamic-qris-button"
                    onClick={handleShowDynamicQris}
                  >
                    <Typography
                      fontWeight="bold"
                      sx={{ color: 'primary.mudahWhite' }}
                    >
                      Buat QRIS Dinamis
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <QrisCopywriting />
              </Grid>
            </Grid>
          </Paper>
        </VerifBlock>
        <MudahCopyright />
      </div>

      {/* Modal - Dynamic QRIS */}
      <Modal open={showDynamicQris} onClose={handleHideDynamicQris}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="body1">QRIS Dinamis</Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h2" fontWeight="bold">
                {dynamicQRISValue.toLocaleString('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                })}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Box
                  component="img"
                  src={QRIS}
                  sx={{
                    height: { xs: 30, md: 45 },
                  }}
                ></Box>
              </Grid>
              <Grid item>
                <Box
                  component="img"
                  src={GPN}
                  sx={{
                    height: { xs: 30, md: 45 },
                  }}
                ></Box>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={12} sx={{ mt: 2 }}>
              {dynamicQrisLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  component="img"
                  src={dynamicQrisImage}
                  sx={{
                    maxWidth: '100%',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                sx={{ height: '50px', borderRadius: '25px', mt: 4 }}
                id="send-dynamic-qris-button"
                onClick={handleFinishDynamicQris}
              >
                <Typography
                  fontWeight="bold"
                  sx={{ color: 'primary.mudahWhite' }}
                >
                  Selesai
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Download QRIS */}
      <Modal open={downloadModalOpen} onClose={handleDownloadModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Stack justifyContent="flex-end">
            <IconButton
              aria-label="close"
              sx={{ ml: 'auto' }}
              onClick={handleDownloadModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Grid container justifyContent="center" spacing={3}>
            <Grid item container justifyContent="center" xs={12}>
              <Box
                component="img"
                src={DownloadIcon}
                sx={{
                  height: 70,
                }}
              ></Box>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h2" fontWeight="bold">
                Download QRIS
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  color: 'primary.mudahDarkGrey',
                }}
              >
                Kami sarankan untuk mengunduh PDF untuk keperluan print QRIS
                pada toko/acara offline.
              </Typography>
            </Grid>

            <Grid container item spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <a
                  href={staticQrisPNGURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="qris-mudah-indonesia.png"
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      color: 'primary.main',
                      borderColor: 'primary.main',
                    }}
                  >
                    PNG
                  </Button>
                </a>
              </Grid>
              <Grid item xs={12} md={6}>
                <a
                  href={staticQrisPDFURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="qris-mudah-indonesia.pdf"
                >
                  <Button variant="contained" fullWidth>
                    PDF
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default Qris;
