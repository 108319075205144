import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

// import backend api
import { baseURLV2 } from '../../api';

export const useCreateKyc = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/kyc/create`,
        value,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
}