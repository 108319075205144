import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// import auth components
import useAuth from '../../../hooks/useAuth';

const KYCForm = ({
  handleNext,
  phoneNumber,
  idNumber,
  name,
  updateKycData,
}) => {
  const [validNumber, setValidNumber] = useState(false);
  const [validId, setValidId] = useState(false);

  const { user, refreshAuth } = useAuth();
  const navigate = useNavigate();

  const phoneValidation = (e) => {
    e.preventDefault();
    const reg = new RegExp(e.target.pattern);
    let numberIsValid = reg.test(e.target.value);
    setValidNumber(numberIsValid);
    if (numberIsValid) {
      updateKycData('phoneNumber', e.target.value);
    }
  };

  const idValidation = (e) => {
    e.preventDefault();
    const reg = new RegExp(e.target.pattern);
    let idNumberIsValid = reg.test(e.target.value);
    setValidId(idNumberIsValid);
    if (idNumberIsValid) {
      updateKycData('idNumber', e.target.value);
    }
  };

  const formHandler = async (e) => {
    e.preventDefault();

    try {
      handleNext();
    } catch (e) {
      // console.log(e.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user == null) {
        const res = await refreshAuth();
        if (!res.success) {
          navigate('/logout');
        }
      } else {
        try {
          let userPhoneNumber = user.phone_number;
          let phoneWithoutCountryCode = userPhoneNumber.replace('+62', '0');
          setValidNumber(true);
          updateKycData('phoneNumber', phoneWithoutCountryCode);
        } catch (error) {
          // console.log(error);
        }
      }
    };
    fetchData();
  }, [user, navigate, refreshAuth, updateKycData]);

  return (
    <Box component="form" onSubmit={formHandler}>
      <Grid container justifyContent="center" spacing={2} sx={{ width: 1 }}>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 4 }}
        >
          <TextField
            required
            id="full-name"
            name="full name"
            label="Nama Lengkap Sesuai KTP"
            placeholder="Contoh: Ali Akbar"
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={name}
            onChange={(e) => updateKycData('name', e.target.value)}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 2 }}
        >
          <TextField
            required
            id="phone-number"
            name="phone number"
            label="Nomor HP"
            placeholder="Contoh: 08xxxxxxxxxx"
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '^[0-9]{8,13}$',
              minLength: 8,
              maxLength: 13,
            }}
            InputLabelProps={{ shrink: true }}
            value={phoneNumber}
            onChange={phoneValidation}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 2 }}
        >
          <TextField
            required
            id="id-number"
            name="id number"
            label="Nomor identitas sesuai KTP"
            placeholder="Contoh: 321xxxxxxxxxx"
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '^[0-9]{16}$',
              minLength: 16,
              maxLength: 16,
            }}
            defaultValue={idNumber}
            onChange={idValidation}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 4 }}
        >
          <Button
            variant="contained"
            fullWidth
            disabled={name === '' || !validNumber || !validId}
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            id="kyc-button"
          >
            <Typography sx={{ color: 'primary.mudahWhite' }}>
              Selanjutnya
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KYCForm;
