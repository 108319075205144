import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { baseURLV2 } from '../../api';

export const useGetPayments = () => {
  return useQuery({
    queryKey: ['payments'],
    staleTime: 5000,
    queryFn: async () => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/transaction/payments/get`,
        {},
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data.payments;
    },
  });
}

export const useGetSettlements = () => {
  return useQuery({
    queryKey: ['settlements'],
    staleTime: 5000,
    queryFn: async () => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/transaction/settlements/get`,
        {},
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data.settlements;
    },
  });
}
