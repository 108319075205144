import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { baseURLV2 } from '../../api';

export const useGetUserNotification = () => {
  return useQuery({
    queryKey: ['userNotification'],
    staleTime: 60000,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/notification/get`,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};

export const useGetBroadcastNotification = () => {
  return useQuery({
    queryKey: ['broadcastNotification'],
    staleTime: 60000,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/notification/broadcast/get`,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};

export const useGetNotificationInfo = () => {
  return useQuery({
    queryKey: ['notificationInfo'],
    staleTime: 10000,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/notification/info`,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};

export const useMarkAsRead = () => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/notification/read`,
        {},
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};
