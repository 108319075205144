import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import mui components
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { set } from 'lodash';

const LoginForm = ({ handleNext, setUserPhoneNumber, setOtpChannel }) => {
  const [validNumber, setValidNumber] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  const phoneValidation = (e) => {
    const reg = new RegExp(e.target.pattern);
    let numberIsValid = reg.test(e.target.value);
    setValidNumber(numberIsValid);
    numberIsValid ? setPhoneNumber(e.target.value) : setPhoneNumber('');
  };

  const formHandler = async (otpChannel) => {
    setIsLoading(true);
    setOtpChannel(otpChannel);

    const phoneWithCountryCode =
      '+62' + phoneNumber.slice(1, phoneNumber.length);

    const result = await login(phoneWithCountryCode, otpChannel);
    if (!result.success) {
      if (result.reason === 'not_whitelisted') {
        alert(
          'Nomor telepon belum bisa digunakan untuk mendaftar. Tunggu undangan dari kami ya!'
        );
      }
      if (result.reason === 'not_registered') {
        alert('Nomor telepon belum terdaftar. Silakan daftar terlebih dahulu!');
        navigate('/register');
      }
      setIsLoading(false);
    } else {
      setUserPhoneNumber(phoneNumber);
      setIsLoading(false);
      handleNext();
    }
  };

  return (
    <Box component="form" sx={{ width: 1, height: 1 }}>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center" xs={12}>
          <TextField
            required
            id="phone-number"
            name="phone number"
            label="Nomor Telepon"
            placeholder="Contoh: 0812xxxxxxxx"
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '^[0-9]{8,13}$',
              minLength: 8,
              maxLength: 13,
            }}
            sx={{
              mt: 8,
            }}
            onChange={phoneValidation}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Button
            variant="contained"
            fullWidth
            disabled={!validNumber || isLoading}
            onClick={() => {
              formHandler('whatsapp');
            }}
            sx={{ height: '50px', borderRadius: '25px', mt: 4 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Masuk via Whatsapp
              </Typography>
            )}
          </Button>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Button
            variant="outlined"
            fullWidth
            disabled={!validNumber || isLoading}
            onClick={() => {
              formHandler('sms');
            }}
            sx={{ height: '50px', borderRadius: '25px', mt: 4 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahDarkGrey' }}>
                Masuk via SMS
              </Typography>
            )}
          </Button>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Link to="/register" style={{ textDecoration: 'none' }}>
            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 4 }}
              to="/register"
            >
              Belum memiliki akun Mudah Indonesia?{' '}
              <Box
                component="span"
                sx={{
                  color: '#0049ad',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Daftar di sini
              </Box>
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
