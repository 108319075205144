import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

// import mui components
import { Grid, CircularProgress } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { user, refreshAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserData = async () => {
      if (!user) {
        const res = await refreshAuth();
        if (!res.success) {
          navigate('/logout');
        }
      }
      setIsLoading(false);
    };
    checkUserData();
  }, [refreshAuth, user, navigate]);

  return (
    <>
      {isLoading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ProtectedRoute;
