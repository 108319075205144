import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import auth components
import useAuth from '../../hooks/useAuth';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const initiateLogout = async () => {
      const res = await logout();
      if (res.success) {
        navigate('/login');
      } else {
        // console.log('Logout tidak berhasil');
      }
    };
    initiateLogout();
  }, [logout, navigate]);

  return <div></div>;
};

export default Logout;
