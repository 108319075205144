import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import page components
import InputOTP from '../InputOTP';

const RegisterOTP = ({
  handleNext,
  phoneNumber,
  phoneWithCountryCode,
  otpChannel,
}) => {
  const [otpValid, setOtpValid] = useState(false);
  const [otpNumber, setOtpNumber] = useState('');
  const [otpUnverified, setOtpUnverified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { verifyOTP, resendOTP } = useAuth();
  const navigate = useNavigate();

  const otpReg = new RegExp('^[0-9]{6}$');

  const redactedPhoneNumber = phoneNumber.replace(
    phoneNumber.substring(5, phoneNumber.length - 2),
    '*'.repeat(phoneNumber.length - 7)
  );

  const otpChangeHandler = (pinNumber) => {
    setOtpNumber(pinNumber);
    otpReg.test(otpNumber) ? setOtpValid(true) : setOtpValid(false);
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();

    await resendOTP(phoneWithCountryCode, otpChannel);

    alert('Kode OTP telah dikirimkan ulang melalui sms ke nomor anda.');
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const result = await verifyOTP(otpNumber);
    if (!result.success) {
      if (result?.reason === 'logged_out') {
        alert('Kode OTP telah kadaluarsa. Silakan daftar kembali.');
        navigate('/register');
      }
      setOtpUnverified(true);
      setIsLoading(false);
    } else {
      setOtpUnverified(false);
      setIsLoading(false);
      handleNext();
    }

    setIsLoading(false);
  };

  return (
    <Box component="form" onSubmit={formHandler} sx={{ width: 1, height: 1 }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '80%' }}
      >
        <Grid item container justifyContent="center" sx={{ width: 1 }}>
          <Grid item xs={12}>
            <Typography sx={{ mt: 4 }}>Masukan kode OTP</Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.mudahDarkGrey' }}
            >
              Kode OTP telah dikirimkan melalui sms ke {redactedPhoneNumber}
            </Typography>
          </Grid>

          <Grid container justifyContent="center" xs={12} sx={{ mt: 2 }}>
            <InputOTP pinChangeHandler={otpChangeHandler} />
          </Grid>

          {otpUnverified && (
            <Typography
              variant="subtitle2"
              sx={{
                color: 'primary.mudahRed',
                mx: 'auto',
                textAlign: 'center',
              }}
            >
              OTP salah! Harap masukan kembali kode OTP.
            </Typography>
          )}

          <Typography
            variant="subtitle2"
            onClick={handleResendOtp}
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
              mt: 1,
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            Kirim ulang kode OTP?
          </Typography>
        </Grid>

        <Grid item sx={{ width: 1, mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!otpValid || isLoading}
          >
            <Typography sx={{ color: 'primary.mudahWhite' }}>
              Selanjutnya
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterOTP;
