import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import static data
import bizTypeList from '../../../data/bizTypeList';

const RegisterForm = ({
  handleNext,
  bizName,
  phoneNumber,
  bizType,
  updateFormData,
}) => {
  const [validNumber, setValidNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { register } = useAuth();
  const navigate = useNavigate();

  const phoneValidation = (e) => {
    e.preventDefault();
    const reg = new RegExp(e.target.pattern);
    let numberIsValid = reg.test(e.target.value);
    setValidNumber(numberIsValid);
    if (numberIsValid) {
      updateFormData('phoneNumber', e.target.value);
    }
  };

  const formHandler = async (otpChannel) => {
    if (bizName === '' || phoneNumber === '' || bizType === '') {
      alert('Mohon isi semua kolom yang tersedia');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const phoneWithCountryCode =
      '+62' + phoneNumber.slice(1, phoneNumber.length);

    try {
      const res = await register(phoneWithCountryCode, bizName, otpChannel);
      if (!res.success) {
        if (res.reason === 'account_already_registered') {
          alert('Nomor telepon sudah terdaftar');
          setIsLoading(false);
          navigate('/login');
          return;
        }
        if (res.reason === 'account_not_whitelisted') {
          alert('Nomor telepon belum terdaftar di whitelist. Tunggu undangan dari kami ya!');
          setIsLoading(false);
          navigate('/register');
          return;
        }
        alert(
          'Terjadi kesalahan, silakan coba lagi atau hubungi customer service.'
        );
        setIsLoading(false);
        return;
      }

      updateFormData('bizName', bizName);
      updateFormData('phoneNumber', phoneNumber);
      updateFormData('bizType', bizType);
      updateFormData('phoneWithCountryCode', phoneWithCountryCode);
      updateFormData('otpChannel', otpChannel);

      setIsLoading(false);

      handleNext();
    } catch (e) {
      // console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <Box component="form">
      <Grid container justifyContent="space-center" sx={{ height: '90%' }}>
        <Grid item xs={12} sx={{ width: 1 }}>
          <TextField
            required
            id="biz-name"
            name="biz name"
            label="Nama Usaha"
            placeholder="Contoh: PT Jaya Abadi"
            fullWidth
            sx={{
              mt: 4,
            }}
            onChange={(e) => {
              e.preventDefault();
              updateFormData('bizName', e.target.value);
            }}
          />

          <TextField
            required
            id="phone-number"
            name="phone number"
            label="Nomor Telepon"
            placeholder="Contoh: 08xxxxxxxxxx"
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '^[0-9]{8,13}$',
              minLength: 8,
              maxLength: 13,
            }}
            sx={{
              mt: 2,
            }}
            onChange={phoneValidation}
          />

          <TextField
            required
            select
            id="biz-type"
            name="biz type"
            label="Jenis Usaha"
            fullWidth
            sx={{
              mt: 2,
            }}
            onChange={(e) => {
              e.preventDefault();
              updateFormData('bizType', e.target.value);
            }}
          >
            {bizTypeList.map((bizType) => (
              <MenuItem
                value={bizType.name}
                disabled={bizType.isCategory}
                key={bizType.name}
              >
                {bizType.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sx={{ width: 1, mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            disabled={!validNumber || isLoading}
            onClick={() => {
              formHandler('whatsapp');
            }}
            sx={{ height: '50px', borderRadius: '25px' }}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Daftar via Whatsapp
              </Typography>
            )}
          </Button>

          <Button
            variant="outlined"
            fullWidth
            disabled={!validNumber || isLoading}
            onClick={() => {
              formHandler('sms');
            }}
            sx={{ height: '50px', borderRadius: '25px', mt: 1 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahDarkGrey' }}>
                Daftar via SMS
              </Typography>
            )}
          </Button>

          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 4 }}
              to="/login"
            >
              Sudah memiliki akun Mudah Indonesia?{' '}
              <Box
                component="span"
                sx={{
                  color: '#0049ad',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Masuk di sini
              </Box>
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterForm;
