import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import mui components
import { ThemeProvider } from '@mui/material';
import { appTheme } from './themes/theme';

// import components
import ProtectedRoute from './pages/components/ProtectedRoute';
import AuthenticatedRoute from './pages/components/AuthenticatedRoute';

// import static assets
import './App.css';

// pages
import Home from './pages/js/Home';
import Qris from './pages/js/Qris';
import Transaction from './pages/js/Transaction';
import Balance from './pages/js/Balance';
import Profile from './pages/js/Profile';
import Verification from './pages/js/Verification';
import Notification from './pages/js/Notification';
import KYC from './pages/js/KYC';
// auth pages
import Login from './pages/js/Login';
import Register from './pages/js/Register';
import Logout from './pages/js/Logout';
// import ResetPin from './pages/js/ResetPin';

import { AuthProvider } from './context/AuthProvider';

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <AuthProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route path="/">
                  {/* Pages */}
                  <Route
                    index
                    element={
                      <ProtectedRoute>
                        <Home />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="qris"
                    element={
                      <ProtectedRoute>
                        <Qris />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="transaction"
                    element={
                      <ProtectedRoute>
                        <Transaction />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="balance"
                    element={
                      <ProtectedRoute>
                        <Balance />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="verification"
                    element={
                      <ProtectedRoute>
                        <Verification />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="notification"
                    element={
                      <ProtectedRoute>
                        <Notification />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="kyc"
                    element={
                      <ProtectedRoute>
                        <KYC />
                      </ProtectedRoute>
                    }
                  />

                  {/* Auth */}
                  <Route
                    path="login"
                    element={
                      <AuthenticatedRoute>
                        <Login />
                      </AuthenticatedRoute>
                    }
                  />
                  <Route
                    path="register"
                    element={
                      <AuthenticatedRoute>
                        <Register />
                      </AuthenticatedRoute>
                    }
                  />
                  {/* <Route
                    path="auth/pin/reset"
                    element={
                      <AuthenticatedRoute>
                        <ResetPin />
                      </AuthenticatedRoute>
                    }
                  /> */}
                  <Route path="logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<h1>404 Not Found</h1>} />
              </Routes>
            </QueryClientProvider>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
