import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import mui components
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VerifBlock = ({ children }) => {
  const [verified, setVerified] = useState('none');
  const [ctaClosed, setCtaClosed] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    const setUserVerification = async () => {
      if (user) {
        setVerified(user.verification_status);
        setDataLoading(false);
      }
    };
    setUserVerification();
  }, [user]);

  const handleCtaClose = () => {
    setCtaClosed(true);
  };

  if (dataLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 200,
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    if (verified === 'VERIFIED') {
      if (!ctaClosed) {
        return (
          <>
            <Card sx={{ mt: 2 }} variant="outlined">
              <CardContent>
                <>
                  <Grid container>
                    <Grid item container justifyContent="space-between" xs={12}>
                      <Grid item>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ fontSize: { xs: 16, md: 20 } }}
                        >
                          Selamat, kamu berhasil memiliki QRIS untuk usahamu! 🎉
                        </Typography>
                      </Grid>
                      <Grid item>
                        <CloseIcon
                          onClick={handleCtaClose}
                          sx={{ cursor: 'pointer' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ mt: 1, fontSize: { xs: 12, md: 15 } }}
                      >
                        Yuk mulai transaksi sekarang dan bisa cairkan ke
                        bank/e-wallet instan.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        component={Link}
                        to="/qris"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      >
                        Cek QRIS
                      </Button>
                    </Grid>
                  </Grid>
                </>
              </CardContent>
            </Card>
            {children}
          </>
        );
      }
      return <>{children}</>;
    } else if (verified === 'PENDING') {
      return (
        <Card sx={{ mt: 2 }} variant="outlined">
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: 16, md: 20 } }}
                >
                  ‍🔎 Dokumen kamu sudah kami terima dan sedang diproses!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{ mt: 1, fontSize: { xs: 12, md: 15 } }}
                >
                  Sebentar ya, proses biasanya memakan waktu 5 menit hingga maksimal 1 hari.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    } else if (verified === 'REJECTED') {
      return (
        <Card sx={{ mt: 2 }} variant="outlined">
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: 16, md: 20 } }}
                >
                  Pengajuan kamu belum memenuhi syarat nih! 😔
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{ mt: 1, fontSize: { xs: 12, md: 15 } }}
                >
                  Supaya upgradenya tidak ditolak lagi, kamu bisa lihat
                  solusinya di sini.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  component={Link}
                  to="/kyc"
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  Coba Lagi Yuk
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    } else {
      return (
        <Card sx={{ mt: 2 }} variant="outlined">
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: 16, md: 20 } }}
                >
                  Buat QRIS untuk toko kamu di Mudah sekarang, GRATIS!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{ mt: 1, fontSize: { xs: 12, md: 15 } }}
                >
                  Satu QRIS untuk semua pembayaran dari berbagai bank dan dompet
                  digital ⚡
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  component={Link}
                  to="/kyc"
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  Ajukan Sekarang
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
  }
};

export default VerifBlock;
