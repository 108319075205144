import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// import static assets
import '../css/MainContainer.css';

// import mui components
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';

// import page components
import VerifBlock from '../components/auth/VerifBlock';
import MudahCopyright from '../components/MudahCopyright';

// import data hooks
import { useGetTransactionSummary } from '../../hooks/services/home';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  tension: 0.4,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

// Create label of last 12 months in string
const today = new Date();
const last6Months = Array.from({ length: 6 }, (_, i) => {
  let monthInt = today.getMonth() - i;
  let year = today.getFullYear();
  if (monthInt < 0) {
    monthInt = 12 + monthInt;
    year = year - 1;
  }
  const month = monthInt + 1;
  const monthName = new Date(`${month}/1/${year}`).toLocaleString('default', {
    month: 'short',
  });
  return `${monthName} ${year}`;
});
// reverse order of last12Months
last6Months.reverse();

const initialChartData = {
  labels: last6Months,
  datasets: [
    {
      fill: true,
      label: 'Penjualan Bulanan',
      data: [],
      borderColor: 'rgb(0, 73, 173)',
      backgroundColor: 'rgba(0, 73, 173, 0.6)',
    },
  ],
};

const InfoBox = ({ title, value }) => {
  return (
    <Paper elevation={2} sx={{ width: 1, p: 3, borderRadius: 4 }}>
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{ color: 'primary.mudahLightGrey' }}
      >
        {title}
      </Typography>
      <Typography variant="h2" fontWeight="bold">
        {value}
      </Typography>
    </Paper>
  );
};

function calcChartData(monthlyData) {
  // Group transactions by month
  const groupedTransactions = monthlyData.reduce((acc, tx) => {
    const txDate = new Date(tx.month_year);
    const monthInt = txDate.getMonth();
    const year = txDate.getFullYear();
    const month = monthInt + 1;
    const monthName = new Date(`${month}/1/${year}`).toLocaleString('default', {
      month: 'short',
    });
    const key = `${monthName} ${year}`;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(tx);
    return acc;
  }, {});

  // Create data for chart
  const chartValues = last6Months.map((month) => {
    const monthData = groupedTransactions[month];
    const monthTotal = monthData
      ? monthData.reduce((acc, transaction) => acc + transaction.value, 0)
      : 0;

    return monthTotal;
  });

  return {
    labels: last6Months,
    datasets: [
      {
        fill: true,
        label: 'Penjualan Bulanan',
        data: chartValues,
        borderColor: 'rgb(0, 73, 173)',
        backgroundColor: 'rgba(0, 73, 173, 0.6)',
      },
    ],
  };
}

const Home = () => {
  const [dateFilter, setDateFilter] = useState('all');

  const [grossSales, setGrossSales] = useState(0);
  const [netIncome, setNetIncome] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [chartData, setChartData] = useState(initialChartData);

  const { isLoading, data } = useGetTransactionSummary();

  const handleDateFilter = (event) => {
    setDateFilter(event.target.value);
  };

  useEffect(() => {
    if (!isLoading && data) {
      setGrossSales(data.gross);
      setNetIncome(data.nett);
      setTotalTransactions(data.total_transaction);
      setChartData(calcChartData(data.monthly_summaries));
    }
  }, [isLoading, data]);

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Beranda" />
        <VerifBlock>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container sx={{ mt: 2 }}>
              <Grid item container spacing={{ xs: 1, md: 3 }}>
                <Grid item container justifyContent="center" xs={12} md={4}>
                  <InfoBox
                    title="Total Penjualan"
                    value={grossSales.toLocaleString('id-ID', {
                      style: 'currency',
                      currency: 'IDR',
                    })}
                  />
                </Grid>
                <Grid item container justifyContent="center" xs={12} md={4}>
                  <InfoBox
                    title="Pendapatan Bersih"
                    value={netIncome.toLocaleString('id-ID', {
                      style: 'currency',
                      currency: 'IDR',
                    })}
                  />
                </Grid>
                <Grid item container justifyContent="center" xs={12} md={4}>
                  <InfoBox
                    title="Jumlah Transaksi (Order)"
                    value={totalTransactions}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Paper
                  elevation={4}
                  sx={{
                    mt: 4,
                    mx: 'auto',
                    p: 3,
                    width: 1,
                    borderRadius: 4,
                  }}
                >
                  <Grid container sx={{ mb: 4 }}>
                    <Grid item xs={12} md={9}>
                      <Hidden mdDown>
                        <Typography variant="h2" fontWeight="bold">
                          Pemasukan
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: 'primary.mudahLightGrey' }}
                        >
                          Dalam Pemasukan, terdapat 2 bagian dari penjualan dan
                          pendapatan bersih.
                        </Typography>
                      </Hidden>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth sx={{ mt: { xs: 2, md: 0 } }}>
                        <InputLabel id="date-filter-label">
                          Tanggal Transaksi
                        </InputLabel>
                        <Select
                          id="date-filter"
                          label="Tanggal Transaksi"
                          value={dateFilter}
                          onChange={handleDateFilter}
                        >
                          <MenuItem value="all">Semua</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      xs={12}
                      sx={{ mt: 2 }}
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={12}
                        md={'auto'}
                        spacing={1}
                      >
                        <Grid item>
                          <Box
                            sx={{
                              height: '20px',
                              width: '20px',
                              borderRadius: '5px',
                              backgroundColor: 'primary.main',
                            }}
                          ></Box>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Total Penjualan
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box sx={{ height: '45vh' }}>
                    <Line
                      options={chartOptions}
                      data={chartData}
                      height="100%"
                      width="100%"
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
        </VerifBlock>
        <MudahCopyright />
      </div>
    </>
  );
};

export default Home;
