import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { baseURLV2 } from '../../api';

export const useGetTransactionSummary = () => {
  return useQuery({
    queryKey: ['transactionSummary'],
    staleTime: 60000,
    queryFn: async () => {
      const response = await axios.post(
        `${baseURLV2}/teras/v1/transaction/summary`,
        {},
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return response.data;
    },
  });
};
