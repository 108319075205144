import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

// import ui components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

// import page components
import AuthBox from '../components/auth/AuthBox';
import KYCForm from '../components/kyc/KYCForm';
import KYCUpload from '../components/kyc/KYCUpload';
import KYCAddress from '../components/kyc/KYCAddress';
import KYCBank from '../components/kyc/KYCBank';
import KYCSuccess from '../components/kyc/KYCSuccess';
import MudahLogoPair from '../components/MudahLogoPair';

const steps = [
  'Data Diri',
  'Foto KTP',
  'Alamat',
  'Rekening',
  'KYC Sedang Diproses',
];

function getStepContent(step, handleNext, kycData, updateKycData) {
  switch (step) {
    case 0:
      return (
        <KYCForm
          handleNext={handleNext}
          {...kycData}
          updateKycData={updateKycData}
        />
      );
    case 1:
      return (
        <KYCUpload
          handleNext={handleNext}
          {...kycData}
          updateKycData={updateKycData}
        />
      );
    case 2:
      return (
        <KYCAddress
          handleNext={handleNext}
          {...kycData}
          updateKycData={updateKycData}
        />
      );
    case 3:
      return (
        <KYCBank
          handleNext={handleNext}
          {...kycData}
          updateKycData={updateKycData}
        />
      );
    case 4:
      return <KYCSuccess kycData={kycData} />;
    default:
      throw new Error('Unknown step');
  }
}

const INITIAL_FORM_DATA = {
  name: '',
  phoneNumber: '',
  idNumber: '',
  idImageURL: '',
  selfieImageURL: '',
  address: {
    fullAddress: '',
    province: '',
    city: '',
    district: '',
    postalCode: '',
  },
  settlementBank: {
    bankCode: '',
    bankName: '',
    bankType: '',
    accountName: '',
    accountNo: '',
  },
};

const KYC = () => {
  const [activeStep, setActiveStep] = useState(0);

  // name, idnumber, idImage, selfieImage, address, settlementBank
  const [kycData, setKycData] = useState(INITIAL_FORM_DATA);

  const updateKycData = (key, value) => {
    setKycData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <AuthBox>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {activeStep === steps.length ? (
            <Navigate to="/" />
          ) : (
            <>
              <MudahLogoPair />
              <Typography
                component="h1"
                variant="h4"
                align="left"
                sx={{
                  mt: 2,
                  fontWeight: '600',
                }}
              >
                Ajukan Verifikasi
              </Typography>
              <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Typography variant="h4" align="center" sx={{ mt: 2 }}>
                {activeStep + 1} dari 5 —{' '}
                <Box component="span" fontWeight="bold">
                  {steps[activeStep]}
                </Box>
              </Typography>
              {getStepContent(activeStep, handleNext, kycData, updateKycData)}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {activeStep !== 4 && (
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              🔒 Semua info kamu dijamin rahasia dan tidak disalahgunakan
            </Typography>
          )}
        </Grid>
      </Grid>
    </AuthBox>
  );
};

export default KYC;
