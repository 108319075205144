import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { baseURLV2 } from '../../api';

const useGetQrisPng = () => {
  return useQuery({
    queryKey: ['qrisPng'],
    staleTime: Infinity,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/qris/static/png`,
        {
          timeout: 10000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};

const useGetQrisPdf = () => {
  return useQuery({
    queryKey: ['qrisPdf'],
    staleTime: Infinity,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/qris/static/pdf`,
        {
          timeout: 10000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};

export const useCreateDynamicQris = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios
        .post(
          `${baseURLV2}/teras/v1/qris/dynamic/create`,
          {
            amount: value.amount,
            customer_name: value.customerName,
            customer_phone_number: value.customerPhoneNumber,
          },
          {
            timeout: 7000,
            withCredentials: true,
          }
        )
        .catch((err) => {
          return { data: { success: false } };
        });
      return data;
    },
  });
};
