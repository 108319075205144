import axios from 'axios';
import { createContext, useState } from 'react';

import { baseURLV2 } from '../api';

const AuthStatus = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
  OTP_AUTHENTICATED: 'OTP_AUTHENTICATED',
  PIN_AUTHENTICATED: 'PIN_AUTHENTICATED',
};

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(AuthStatus.UNAUTHENTICATED);
  const [user, setUser] = useState(null);

  const setUserData = async () => {
    if (user) return { success: true };

    // get user data
    const { data } = await axios
      .get(`${baseURLV2}/teras/v1/account/get`, {
        timeout: 7000,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .catch((err) => {
        return { success: false };
      });

    if (data) {
      setUser(data);
      return { success: true };
    }
  };

  const login = async (phoneWithCountryCode, otpChannel) => {
    const { data } = await axios.post(
      `${baseURLV2}/teras/v1/auth/login`,
      {
        phone_number: phoneWithCountryCode,
        otp_channel: otpChannel,
      },
      {
        timeout: 7000,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );

    if (data.success) {
      setAuth(AuthStatus.AUTHENTICATED);
      return { success: true, registered: data.registered };
    }

    setAuth(AuthStatus.UNAUTHENTICATED);
    if (data.whitelisted === false) {
      return { success: false, reason: 'not_whitelisted' };
    }
    if (data.registered === false) {
      return { success: false, reason: 'not_registered' };
    }
    return { success: false, reason: 'unknown' };
  };

  const verifyOTP = async (otp) => {
    const { data } = await axios.post(
      `${baseURLV2}/teras/v1/auth/verify/otp`,
      {
        otp_code: otp,
      },
      {
        timeout: 7000,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );

    if (data.success) {
      setAuth(AuthStatus.OTP_AUTHENTICATED);
      return { success: true };
    }

    if (data.reason === 'expired_or_missing_otp') {
      logout();
      return { success: false, reason: 'logged_out' };
    }

    return { success: false, reason: data.reason };
  };

  const resendOTP = async (phoneWithCountryCode, otpChannel) => {
    const { data } = await axios
      .post(
        `${baseURLV2}/teras/v1/auth/resend/otp`,
        {
          phone_number: phoneWithCountryCode,
          otp_channel: otpChannel,
        },
        {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .catch((err) => {
        if (err.response.data) {
          return { data: err.response.data };
        }
      });

    if (data.success) {
      return { success: true };
    }

    return { success: false };
  };

  const accountVerifyPIN = async (pin) => {
    const { data } = await axios
      .post(
        `${baseURLV2}/teras/v1/account/pin/verify`,
        {
          pin: pin,
        },
        {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .catch((err) => {
        if (err.response.data) {
          return { data: err.response.data };
        }
      });

    if (data.success) {
      return { success: true };
    }

    return { success: false, reason: data.reason };
  };

  const verifyPIN = async (pin) => {
    const { data } = await axios
      .post(
        `${baseURLV2}/teras/v1/auth/verify/pin`,
        {
          pin: pin,
        },
        {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .catch((err) => {
        if (err.response.data) {
          return { data: err.response.data };
        }
      });

    if (data.success) {
      setAuth(AuthStatus.PIN_AUTHENTICATED);
      return await setUserData();
    }

    return { success: false, reason: data.reason };
  };

  const logout = async () => {
    if (auth === AuthStatus.UNAUTHENTICATED) return { success: true };
    await axios
      .post(
        `${baseURLV2}/teras/v1/auth/logout`,
        {},
        {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .catch((err) => {
        setAuth(AuthStatus.UNAUTHENTICATED);
        setUser(null);
        return { success: true };
      });

    setAuth(AuthStatus.UNAUTHENTICATED);
    setUser(null);
    return { success: true };
  };

  const register = async (phoneWithCountryCode, businessName, otpChannel) => {
    const { data } = await axios
      .post(
        `${baseURLV2}/teras/v1/auth/register`,
        {
          phone_number: phoneWithCountryCode,
          business_name: businessName,
          otp_channel: otpChannel,
        },
        {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .catch((err) => {
        if (err.response.status === 409 && err.response.data.reason) {
          return {
            data: { success: false, reason: err.response.data.reason },
          };
        }
        return { data: { success: false, reason: 'unknown' } };
      });

    if (data.success) {
      setAuth(AuthStatus.AUTHENTICATED);
      return { success: true };
    }

    setAuth(AuthStatus.UNAUTHENTICATED);
    return { success: false, reason: data.reason };
  };

  const registerFinalise = async (pinNumber, businessType) => {
    const { data } = await axios
      .post(
        `${baseURLV2}/teras/v1/auth/register/finalise`,
        {
          pin_number: pinNumber,
          business_type: businessType,
        },
        {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .catch((err) => {
        return { success: false };
      });

    if (data.success) {
      setAuth(AuthStatus.PIN_AUTHENTICATED);

      // get user data
      const { data } = await axios
        .get(`${baseURLV2}/teras/v1/account/get`, {
          timeout: 7000,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
        .catch((err) => {
          return { success: false };
        });

      if (data) {
        setUser(data);
        return { success: true };
      }
    }

    return { success: false, reason: data.reason };
  };

  const refreshAuth = async () => {
    if (auth === AuthStatus.PIN_AUTHENTICATED) return { success: true };

    const { data } = await axios
      .get(`${baseURLV2}/teras/v1/auth/status`, {
        timeout: 7000,
        withCredentials: true,
      })
      .catch((err) => {
        setAuth(AuthStatus.UNAUTHENTICATED);
        return { success: false };
      });

    if (!data) {
      setAuth(AuthStatus.UNAUTHENTICATED);
      return { success: false };
    }

    if (data.success) {
      if (data.user_status === 'pin_verified') {
        setAuth(AuthStatus.PIN_AUTHENTICATED);
        return await setUserData();
      }

      setAuth(AuthStatus.UNAUTHENTICATED);
      return { success: false };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUserData,
        login,
        verifyOTP,
        resendOTP,
        verifyPIN,
        accountVerifyPIN,
        logout,
        register,
        registerFinalise,
        refreshAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
