import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';

// import mui components
import { Badge, Grid, Typography } from '@mui/material';

// import static assets
import './Header.css';

import { useGetNotificationInfo, useMarkAsRead } from '../hooks/services/notifications';

const Header = ({ pageTitle }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  const { isSuccess, data, refetch } = useGetNotificationInfo();
  const { mutateAsync: markAsRead } = useMarkAsRead();

  useEffect(() => {
    if (isSuccess) {
      setUnreadCount(data.unread_count);
    }
  }, [isSuccess, data]);

  const handleNotificationBellClick = async (e) => {
    e.preventDefault();
    
    await markAsRead();
    await refetch();
    if (isSuccess) {
      setUnreadCount(data.unread_count);
    }

    window.location.href = '/notification';
  }

  return (
    <header>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1" fontWeight="bold">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item>
          <Link className="NotificationIcon" onClick={handleNotificationBellClick}>
            {unreadCount > 0 ? (
              <Badge
                badgeContent={unreadCount}
                color="primary"
              >
                <FaBell className="BellIcon" />
              </Badge>
            ) : (
              <FaBell className="BellIcon" />
            )}
          </Link>
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
