import React, { useEffect, useState } from 'react';

import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';

// import static assets
import '../css/MainContainer.css';

// import components
import MudahCopyright from '../components/MudahCopyright';

// import mui components
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';

// Hooks
import {
  useGetUserNotification,
  useGetBroadcastNotification,
  useMarkAsRead,
} from '../../hooks/services/notifications';

// Helpers
import { rfcToLocalTime } from '../../helper';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [broadcastNotifications, setBroadcastNotifications] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const { isLoading: userNotifLoading, data: userNotifData } =
    useGetUserNotification();
  const { isLoading: broadcastNotifLoading, data: broadcastNotifData } =
    useGetBroadcastNotification();
  const { mutate: markRead } = useMarkAsRead();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  useEffect(() => {
    if (!userNotifLoading && userNotifData) {
      setNotifications(userNotifData.notifications);
    }
  }, [userNotifLoading, userNotifData]);

  useEffect(() => {
    if (!broadcastNotifLoading && broadcastNotifData) {
      setBroadcastNotifications(broadcastNotifData.broadcast_notifications);
    }
  }, [broadcastNotifLoading, broadcastNotifData]);

  useEffect(() => {
    markRead();
  }, [markRead]);

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Notifikasi" />

        <Paper sx={{ mt: 2, py: 2 }}>
          <Box sx={{ mx: 'auto', maxWidth: 750 }}>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              variant="fullwidth"
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{ justifyContent: 'center', mx: 'auto' }}
              >
                <Tab label="Personal" />
                <Tab label="Broadcast" />
              </Tabs>

              {/* Personal */}
              {tabValue === 0 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  {userNotifLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Table>
                      <TableBody>
                        {notifications.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              my: 1,
                            }}
                          >
                            <TableCell align="left">
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 'bold' }}
                                  >
                                    {row.title}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body2">
                                    {row.body}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'primary.mudahDarkGrey',
                                      fontStyle: 'italic',
                                    }}
                                  >
                                    {rfcToLocalTime(row.created_at)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              )}

              {/* Broadcast */}
              {tabValue === 1 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  {broadcastNotifLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Table>
                      <TableBody>
                        {broadcastNotifications.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              my: 1,
                            }}
                          >
                            <TableCell align="left">
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 'bold' }}
                                  >
                                    {row.title}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="body2">
                                    {row.body}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'primary.mudahDarkGrey',
                                      fontStyle: 'italic',
                                    }}
                                  >
                                    {rfcToLocalTime(row.created_at)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              )}
            </Box>
          </Box>
        </Paper>

        <MudahCopyright />
      </div>
    </>
  );
};

export default Notification;
