import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';

// import static assets
import '../css/MainContainer.css';

// import data hooks
import { useGetSettlements } from '../../hooks/services/transaction';
import {
  useGetBalance,
  useGetSmtAvailability,
  useGetSmtAccounts,
  useCreateSettlementAccount,
  useDeleteSettlementAccount,
  useCreateSettlement,
} from '../../hooks/services/settlement';

// import page components
import MudahCopyright from '../components/MudahCopyright';
import InputPin from '../components/InputPin';
import BigFlipCopywriting from '../components/footnotes/BigFlip';
import InstantSettlementCopywriting from '../components/footnotes/InstantSettlement';

// import helper functions
import { cyrb53, rfcToLocalTime } from '../../helper';

// import auth components
import useAuth from '../../hooks/useAuth';

// import mui components
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Import images
import SettlementSuccessLogo from '../../assets/settlement-success.png';
import SettlementFailedLogo from '../../assets/settlement-failed.png';
import SettlementPendingLogo from '../../assets/settlement-pending.png';

// import bank data
import bankList from '../../data/bankList';

function createBankData(code, name) {
  return { code, name };
}

const bankNames = Object.keys(bankList.bank).map((key) =>
  createBankData(key, bankList.bank[key])
);

const ewalletNames = Object.keys(bankList.ewallet).map((key) =>
  createBankData(key, bankList.ewallet[key])
);

function getSettlementAccountText(bankCode, accountName) {
  return `${bankCode.toUpperCase()} - ${accountName}`;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Balance = () => {
  const queryClient = useQueryClient();

  const { isLoading: balanceLoading, data: balance } = useGetBalance();
  const { isLoading: smtAvailabilityLoading, data: smtAvailability } =
    useGetSmtAvailability();
  const { isLoading: settlementDataLoading, data: settlementData } =
    useGetSettlements();
  const {
    isLoading: smtAccountsLoading,
    data: smtAccounts,
    refetch: refetchSmtAccounts,
  } = useGetSmtAccounts();

  const {
    mutate: createSettlementAccount,
    isLoading: createSmtAccountLoading,
  } = useCreateSettlementAccount();
  const {
    mutate: deleteSettlementAccount,
    isLoading: deleteSmtAccountLoading,
  } = useDeleteSettlementAccount();
  const {
    mutate: createSettlement,
    isLoading: createSettlementLoading,
    
  } = useCreateSettlement();


  const { accountVerifyPIN } = useAuth();

  const [settlementAvailable, setSettlementAvailable] = useState(false);
  const [settlements, setSettlements] = useState([]);
  const [settlementAccounts, setSettlementAccounts] = useState([]);
  const [selectedSettlementAccount, setSelectedSettlementAccount] =
    useState(null);

  const [settlementAmount, setSettlementAmount] = useState(0);
  const [settlementFee, setSettlementFee] = useState(0);
  const [settlementFinal, setSettlementFinal] = useState(0);

  const [bankCode, setBankCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankType, setBankType] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNo, setAccountNo] = useState('');

  const [bankNameSearch, setBankNameSearch] = useState('');

  const [dateFilter, setDateFilter] = useState('all');
  const [reqModalOpen, setReqModalOpen] = useState(false);
  const [addBankModalOpen, setAddBankModalOpen] = useState(false);
  const [deleteBankModalOpen, setDeleteBankModalOpen] = useState(false);
  const [selectBankModalOpen, setSelectBankModalOpen] = useState(false);
  const [withdrawalConfirmModalOpen, setWithdrawalConfirmModalOpen] =
    useState(false);
  const [withdrawalPINModalOpen, setWithdrawalPINModalOpen] = useState(false);
  const [withdrawalSuccessModalOpen, setWithdrawalSuccessModalOpen] =
    useState(false);
  const [withdrawalFailedModalOpen, setWithdrawalFailedModalOpen] =
    useState(false);
  const [withdrawalPendingModalOpen, setWithdrawalPendingModalOpen] =
    useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const [userPin, setUserPin] = useState('');
  const [userPinError, setUserPinError] = useState(false);
  const [userPinValid, setUserPinValid] = useState(false);
  const [userPinLoading, setUserPinLoading] = useState(false);

  useEffect(() => {
    if (smtAvailability) {
      setSettlementAvailable(smtAvailability);
    }
  }, [smtAvailabilityLoading, smtAvailability]);

  useEffect(() => {
    if (settlementData) {
      setSettlements(settlementData);
    }
  }, [settlementDataLoading, settlementData]);

  useEffect(() => {
    if (smtAccounts) {
      setSettlementAccounts(smtAccounts);
      setSelectedSettlementAccount(smtAccounts[0]);
    }
  }, [smtAccountsLoading, smtAccounts]);

  // Modal opening logic
  const handleReqModalOpen = () => setReqModalOpen(true);
  const handleReqModalClose = () => setReqModalOpen(false);

  const handleAddBankModalOpen = () => {
    setReqModalOpen(false);
    setAddBankModalOpen(true);
  };

  const handleAddBankModalClose = () => {
    setAddBankModalOpen(false);
    setReqModalOpen(true);
  };

  const handleDeleteBankModalOpen = () => {
    setReqModalOpen(false);
    setDeleteBankModalOpen(true);
  };

  const handleDeleteBankModalClose = () => {
    setDeleteBankModalOpen(false);
    setReqModalOpen(true);
  };

  const handleSelectBankModalOpen = () => {
    setAddBankModalOpen(false);
    setSelectBankModalOpen(true);
  };

  const handleSelectBankModalClose = () => {
    setSelectBankModalOpen(false);
    setAddBankModalOpen(true);
  };

  const handleDateFilter = (event) => {
    setDateFilter(event.target.value);
  };

  // Modal event handling
  const handleSelectSettlementAccount = (account) => {
    setSelectedSettlementAccount(account);
  };

  const handleReqModalSend = () => {
    if (isNaN(settlementAmount) || settlementAmount === 0) {
      alert('Masukkan nominal penarikan dana!');
      return;
    }

    if (settlementAmount + 3000 > balance) {
      alert(
        'Jumlah penarikan melebihi saldo yang tersedia! Sediakan extra Rp3.000 untuk biaya penarikan dana.'
      );
      return;
    }

    if (settlementAmount < 20500) {
      if (settlementAmount < 10000) {
        alert('Minimal total penarikan adalah Rp10,000');
        return;
      }
      if (selectedSettlementAccount.bank_name === 'Dana') {
        alert('Minimal total penarikan ke DANA adalah Rp20,500');
        return;
      }
    }

    handleWithdrawalConfirmModalOpen();
  };

  const handleAddBankModalSend = async (event) => {
    event.preventDefault();

    createSettlementAccount({
      name: accountName,
      accountNumber: accountNo,
      bankType: bankType.toUpperCase(),
      bankName: bankName,
      bankCode: bankCode,
    });

    queryClient.invalidateQueries({ queryKey: 'smtAccounts' });
    refetchSmtAccounts();
    setSettlementAccounts(smtAccounts);
    setSelectedSettlementAccount(smtAccounts[0]);

    setAddBankModalOpen(false);
    setReqModalOpen(true);
  };

  const handleDeleteBankModalSend = async (event) => {
    event.preventDefault();

    deleteSettlementAccount({
      name: selectedSettlementAccount.name,
      accountNumber: selectedSettlementAccount.account_number,
      bankCode: selectedSettlementAccount.bank_code,
    });

    queryClient.invalidateQueries({ queryKey: 'smtAccounts' });
    refetchSmtAccounts();
    setSettlementAccounts(smtAccounts);
    setSelectedSettlementAccount(smtAccounts[0]);

    setDeleteBankModalOpen(false);
    setReqModalOpen(true);
  };

  const handleWithdrawalConfirmModalSend = async (event) => {
    createSettlement({
      amount: settlementAmount + 3000,
      settlementAccount: selectedSettlementAccount,
    });

    queryClient.invalidateQueries({ queryKey: 'balance' });

    handleWithdrawalPINModalClose();
    handleWithdrawalPendingModalOpen();
  };

  const userPinChangeHandler = (inputNumber) => {
    let pinReg = new RegExp('^[0-9]{6}$');
    setUserPin(inputNumber);
    pinReg.test(userPin) ? setUserPinValid(true) : setUserPinValid(false);
  };

  const handleWithdrawalPINModalSend = async (event) => {
    event.preventDefault();

    setUserPinLoading(true);

    let hashedPin = cyrb53(userPin).toString();
    const result = await accountVerifyPIN(hashedPin);
    if (result.success) {
      try {
        await handleWithdrawalConfirmModalSend();
        setUserPinLoading(false);
        // const profileData = await userData();
        // if (hashedPin === profileData.pinNumber) {
        //   setUserPinError(false);
        //   await handleWithdrawalConfirmModalSend();
        // } else {
        //   setUserPinError(true);
        //   setUserPinLoading(false);
        // }
      } catch (error) {
        // console.log(error);
      }
    }
    else {
      setUserPinError(true);
      setUserPinLoading(false);
    }
  };

  // Tab Change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleWithdrawalConfirmModalOpen = () => {
    setReqModalOpen(false);
    setWithdrawalConfirmModalOpen(true);
  };

  const handleWithdrawalConfirmModalBack = () => {
    setWithdrawalConfirmModalOpen(false);
    setReqModalOpen(true);
  };

  const handleWithdrawalConfirmModalClose = () => {
    setWithdrawalConfirmModalOpen(false);
  };

  const handleWithdrawalPINModalOpen = () => {
    setWithdrawalConfirmModalOpen(false);
    setUserPinLoading(false);
    setUserPinError(false);
    setWithdrawalPINModalOpen(true);
  };

  const handleWithdrawalPINModalClose = () => {
    setWithdrawalPINModalOpen(false);
  };

  const handleWithdrawalSuccessModalOpen = async () => {
    setWithdrawalConfirmModalOpen(false);
    setWithdrawalSuccessModalOpen(true);
  };

  const handleWithdrawalSuccessModalClose = () => {
    setWithdrawalSuccessModalOpen(false);
    window.location.reload();
  };

  const handleWithdrawalFailedModalOpen = async () => {
    setWithdrawalConfirmModalOpen(false);
    setWithdrawalFailedModalOpen(true);
  };

  const handleWithdrawalFailedModalClose = () => {
    setWithdrawalFailedModalOpen(false);
  };

  const handleWithdrawalPendingModalOpen = async () => {
    setWithdrawalConfirmModalOpen(false);
    setWithdrawalPendingModalOpen(true);
  };

  const handleWithdrawalPendingModalClose = () => {
    setWithdrawalPendingModalOpen(false);
    window.location.reload();
  };

  const handleCloseAllModals = () => {
    setReqModalOpen(false);
    setAddBankModalOpen(false);
    setDeleteBankModalOpen(false);
    setWithdrawalConfirmModalOpen(false);
    setWithdrawalPINModalOpen(false);
    setWithdrawalSuccessModalOpen(false);
    setWithdrawalFailedModalOpen(false);
    setWithdrawalPendingModalOpen(false);
  };

  const settlementBlob = (status, isMobile) => {
    if (isMobile) {
      switch (status) {
        case 'SETTLED':
          return (
            <Box
              sx={{
                ml: 'auto',
                width: 3,
                height: 90,
                backgroundColor: 'primary.mudahGreen',
              }}
            />
          );
        case 'PENDING':
          return (
            <Box
              sx={{
                ml: 'auto',
                width: 3,
                height: 90,
                backgroundColor: 'primary.mudahDarkGrey',
              }}
            />
          );
        case 'FAILED':
          return (
            <Box
              sx={{
                ml: 'auto',
                width: 3,
                height: 90,
                backgroundColor: 'primary.mudahRed',
              }}
            />
          );
        default:
          return (
            <Box
              sx={{
                ml: 'auto',
                width: 3,
                height: 90,
                backgroundColor: 'primary.mudahDarkGrey',
              }}
            />
          );
      }
    } else {
      switch (status) {
        case 'SETTLED':
          return (
            <Box
              sx={{
                m: 'auto',
                width: { xs: 15, md: 20 },
                height: { xs: 15, md: 20 },
                borderRadius: 7,
                backgroundColor: 'primary.mudahGreen',
              }}
            />
          );
        case 'PENDING':
          return (
            <Box
              sx={{
                m: 'auto',
                width: { xs: 15, md: 20 },
                height: { xs: 15, md: 20 },
                borderRadius: 7,
                backgroundColor: 'primary.mudahDarkGrey',
              }}
            />
          );
        case 'FAILED':
          return (
            <Box
              sx={{
                m: 'auto',
                width: { xs: 15, md: 20 },
                height: { xs: 15, md: 20 },
                borderRadius: 7,
                backgroundColor: 'primary.mudahRed',
              }}
            />
          );
        default:
          return (
            <Box
              sx={{
                m: 'auto',
                width: { xs: 15, md: 20 },
                height: { xs: 15, md: 20 },
                borderRadius: 7,
                backgroundColor: 'primary.mudahDarkGrey',
              }}
            />
          );
      }
    }
  };

  const settlementAmountText = (status, amount) => {
    let amountText = amount.toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
    });

    switch (status) {
      case 'SETTLED':
        return (
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{
              color: 'primary.mudahGreen',
              mb: 1,
            }}
          >
            {amountText}
          </Typography>
        );
      case 'PENDING':
        return (
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{
              color: 'primary.mudahDarkGrey',
              mb: 1,
            }}
          >
            {amountText}
          </Typography>
        );

      case 'FAILED':
        return (
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{
              color: 'primary.mudahRed',
              mb: 1,
            }}
          >
            {amountText}
          </Typography>
        );

      default:
        return (
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{
              color: 'primary.mudahDarkGrey',
              mb: 1,
            }}
          >
            {amountText}
          </Typography>
        );
    }
  };

  const settlementStatusString = (status) => {
    switch (status) {
      case 'SETTLED':
        return 'Berhasil';
      case 'PENDING':
        return 'Menunggu';
      case 'FAILED':
        return 'Gagal';
      default:
        return 'Menunggu';
    }
  };

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Saldo" />
        <Paper elevation={4} sx={{ mt: 2, p: { xs: '15px', md: '35px' } }}>
          <Box sx={{ mx: 'auto', maxWidth: 750 }}>
            <Grid
              container
              direction="row"
              alignItems="space-between"
              justifyContent="center"
              wrap="nowrap"
            >
              <Grid item xs>
                <Typography variant="h2">Saldo Tersedia</Typography>
              </Grid>
              <Grid item xs="auto">
                {settlementAvailable ? (
                  <Tooltip title="Tersedia" placement="top" arrow>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        backgroundColor: 'primary.mudahGreen',
                        my: 1,
                      }}
                    ></Box>
                  </Tooltip>
                ) : (
                  <Tooltip title="Belum Tersedia" placement="top" arrow>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        backgroundColor: 'primary.mudahRed',
                        my: 1,
                      }}
                    ></Box>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ my: 1 }}>
              <Grid item xs="auto">
                <Typography
                  variant="h2"
                  sx={{ color: 'primary.mudahDarkGrey' }}
                >
                  IDR
                </Typography>
              </Grid>
              <Grid item xs sx={{ ml: 1 }}>
                {balanceLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="h2" fontWeight="bold">
                    {balance.toLocaleString('id-ID', {})}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              fullWidth
              onClick={handleReqModalOpen}
              sx={{ mt: 1, mb: 2, borderRadius: 12 }}
              disabled={!settlementAvailable}
            >
              {settlementAvailable ? (
                <Typography sx={{ color: 'primary.mudahWhite' }}>
                  Tarik Saldo
                </Typography>
              ) : (
                <Typography sx={{ color: 'primary.mudahWhite' }}>
                  Tarik Saldo (Belum Tersedia)
                </Typography>
              )}
            </Button>
            <Divider />
            <Grid
              container
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} md={8}>
                <TextField
                  id="search-box"
                  label="Cari Transaksi"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="date-filter-label">
                    Tanggal Transaksi
                  </InputLabel>
                  <Select
                    id="date-filter"
                    label="Tanggal Transaksi"
                    value={dateFilter}
                    onChange={handleDateFilter}
                  >
                    <MenuItem value="all">Semua</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {settlementDataLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 200,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableBody>
                    {settlements.map((s) => (
                      <TableRow
                        key={s.transaction_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Hidden mdDown>
                            {settlementBlob(s.status, false)}
                          </Hidden>
                          <Hidden mdUp>{settlementBlob(s.status, true)}</Hidden>
                        </TableCell>
                        <TableCell align="left">
                          <Hidden smUp>
                            <Typography
                              variant="body1"
                              sx={{ color: 'primary.mudahDarkGrey' }}
                            >
                              {settlementStatusString(s.status)}
                            </Typography>
                            {settlementAmountText(s.status, s.amount)}
                          </Hidden>

                          <Typography variant="h4" fontWeight="bold">
                            {s.bank_name.toUpperCase() + ' - ' + s.account_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'primary.mudahDarkGrey' }}
                          >
                            Ke {s.account_number}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'primary.mudahDarkGrey' }}
                          >
                            {rfcToLocalTime(s.date_time)}
                          </Typography>
                        </TableCell>
                        <Hidden smDown>
                          <TableCell align="right">
                            {settlementAmountText(s.status, s.amount)}
                            <Typography
                              variant="body1"
                              sx={{ color: 'primary.mudahDarkGrey' }}
                            >
                              {settlementStatusString(s.status)}
                            </Typography>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Paper>
        <MudahCopyright />
      </div>

      {/* Modal - Request Settlement */}
      <Modal open={reqModalOpen} onClose={handleReqModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" fontWeight="bold" sx={{ flexGrow: 0 }}>
                Tarik Saldo
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                sx={{ ml: 'auto' }}
                onClick={handleCloseAllModals}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Stack>

          <Typography variant="body1" sx={{ mt: 1 }}>
            ⚡ Penarikan saldo instant (1-15 menit)
          </Typography>

          <Box component="form">
            <TextField
              required
              id="settlement-amount"
              name="settlement amount"
              label="Jumlah Penarikan"
              placeholder="Contoh: 25000"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp</InputAdornment>
                ),
                inputMode: 'numeric',
                pattern: '[0-9]*',
                endAdornment: (
                  <Button
                    variant="text"
                    sx={{
                      color: 'primary.mudahDarkGrey',
                      textTransform: 'none',
                      fontSize: '0.8rem',
                      p: 0,
                      m: 0,
                    }}
                    onClick={() => {
                      let availableBalance = balance - 3000;
                      if (availableBalance < 10000) {
                        availableBalance = 0;
                        alert(
                          'Saldo yang tersedia di bawah minimal penarikan dana (Rp10,000).'
                        );
                      }
                      availableBalance = Math.floor(availableBalance);
                      setSettlementAmount(availableBalance);
                      setSettlementFee(3000);
                      setSettlementFinal(availableBalance + 3000);
                    }}
                  >
                    Semua
                  </Button>
                ),
              }}
              fullWidth
              sx={{
                mt: 4,
              }}
              value={settlementAmount}
              onChange={(e) => {
                let amount = parseInt(e.target.value);
                if (isNaN(amount) || amount < 0) {
                  amount = 0;
                }
                setSettlementAmount(amount);
                setSettlementFee(3000);
                setSettlementFinal(amount + 3000);
              }}
            />
            <Typography sx={{ mt: 1, color: 'primary.mudahLightGrey' }}>
              {balance
                ? balance.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  })
                : 0}{' '}
              tersedia
            </Typography>

            <FormControl required fullWidth sx={{ mt: 4 }}>
              <InputLabel id="settlement-account">Akun Penarikan</InputLabel>
              {settlementAccounts.length > 0 && (
                <Select
                  id="settlement-account-filter"
                  label="Akun Penarikan"
                  value={
                    selectedSettlementAccount &&
                    getSettlementAccountText(
                      selectedSettlementAccount.bank_name.toUpperCase(),
                      selectedSettlementAccount.name
                    )
                  }
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                    },
                  }}
                >
                  {settlementAccounts.map((account) => (
                    <MenuItem
                      key={account.bank_name + account.name}
                      value={getSettlementAccountText(
                        account.bank_name,
                        account.name
                      )}
                      onClick={() => {
                        handleSelectSettlementAccount(account);
                      }}
                    >
                      <div style={{ whiteSpace: 'normal' }}>
                        <Typography variant="body2" fontWeight="bold">
                          {getSettlementAccountText(
                            account.bank_name,
                            account.name
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {account.account_number}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>

            <Button
              fullWidth
              onClick={handleAddBankModalOpen}
              sx={{
                mt: 1,
                backgroundColor: 'primary.mudahOffWhite',
              }}
            >
              Tambah Akun Penarikan
            </Button>

            <Button
              fullWidth
              onClick={handleDeleteBankModalOpen}
              sx={{
                mt: 1,
                backgroundColor: 'primary.mudahOffwhite',
                color: 'primary.mudahRed',
              }}
            >
              Hapus Akun Penarikan
            </Button>

            <Grid container spacing={2} sx={{ mt: { xs: 2, md: 4 } }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleReqModalSend}
                >
                  Kirim
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleReqModalClose}
                  sx={{
                    color: 'primary.mudahRed',
                    borderColor: 'primary.mudahRed',
                  }}
                >
                  Batal
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Modal>

      {/* Modal - Add Settlement Account */}
      <Modal open={addBankModalOpen} onClose={handleAddBankModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Stack justifyContent="flex-end">
            <IconButton
              aria-label="close"
              sx={{ ml: 'auto' }}
              onClick={handleCloseAllModals}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Typography variant="h6" component="h2" fontWeight="bold">
            Tambah Tujuan Tarik Saldo
          </Typography>
          <Typography sx={{ mt: 1 }}>
            🔒 Tersedia untuk bank dan e-wallet.
          </Typography>

          <Box component="form">
            <TextField
              required
              id="account-name"
              name="account name"
              label="Nama Pemilik Akun"
              placeholder="Contoh: Ali Akbar"
              fullWidth
              sx={{
                mt: 4,
              }}
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />

            <TextField
              required
              id="bank-name"
              name="bank name"
              label="Nama Bank / E-wallet"
              fullWidth
              sx={{
                mt: 4,
              }}
              value={bankName}
              onClick={handleSelectBankModalOpen}
            />

            <TextField
              required
              id="account-no"
              name="account number"
              label="Nomor Rekening / Telepon Terdaftar"
              placeholder="Contoh: 081928xxxxx"
              fullWidth
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              sx={{
                mt: 4,
              }}
              onChange={(e) => setAccountNo(e.target.value)}
            />

            <Grid container spacing={2} sx={{ mt: 4 }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleAddBankModalClose}
                  sx={{
                    color: 'primary.mudahRed',
                    borderColor: 'primary.mudahRed',
                  }}
                >
                  Batal
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleAddBankModalSend}
                  disabled={
                    accountName === '' ||
                    accountNo === '' ||
                    bankName === '' ||
                    createSmtAccountLoading
                  }
                >
                  Tambah
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Modal>

      {/* Modal - Delete Settlement Account */}
      <Modal open={deleteBankModalOpen} onClose={handleDeleteBankModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Stack justifyContent="flex-end">
            <IconButton
              aria-label="close"
              sx={{ ml: 'auto' }}
              onClick={handleCloseAllModals}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Typography variant="h6" component="h2" fontWeight="bold">
            Hapus Tujuan Tarik Saldo
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Nama Bank / E-wallet
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {selectedSettlementAccount &&
                  selectedSettlementAccount.bank_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Nama Pemilik Akun
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {selectedSettlementAccount && selectedSettlementAccount.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Nomor Rekening / Telepon Terdaftar
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {selectedSettlementAccount &&
                  selectedSettlementAccount.account_number}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, p: 1 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleDeleteBankModalClose}
                sx={{
                  color: 'primary.mudahGray',
                  borderColor: 'primary.mudahGray',
                }}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, p: 1 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleDeleteBankModalSend}
                sx={{
                  backgroundColor: 'primary.mudahRed',
                  color: 'primary.mudahWhite',
                }}
                disabled = {deleteSmtAccountLoading}
              >
                Hapus
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Select Bank Account */}
      <Modal open={selectBankModalOpen} onClose={handleSelectBankModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Stack justifyContent="flex-end">
            <IconButton
              aria-label="close"
              sx={{ ml: 'auto' }}
              onClick={handleCloseAllModals}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <TextField
            required
            id="bank-name"
            name="bank name"
            label="Cari Nama Bank / E-wallet"
            placeholder="Contoh: Mandiri"
            fullWidth
            sx={{
              mt: 2,
            }}
            value={bankNameSearch}
            onChange={(e) => setBankNameSearch(e.target.value)}
          />

          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="fullWidth"
              >
                <Tab label="Bank" />
                <Tab label="E-wallet" />
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <Box sx={{ maxHeight: '40vh', overflow: 'auto' }}>
                <List sx={{ p: 0 }}>
                  {bankNames
                    .filter((pair) =>
                      pair.name
                        .toLowerCase()
                        .includes(bankNameSearch.toLowerCase())
                    )
                    .map((n) => (
                      <ListItem disablePadding key={n.code}>
                        <ListItemButton
                          onClick={() => {
                            setBankCode(n.code);
                            setBankName(n.name);
                            setBankType('bank');
                            handleSelectBankModalClose();
                          }}
                        >
                          {n.name}
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Box sx={{ maxHeight: '40vh', overflow: 'auto' }}>
                <List sx={{ p: 0 }}>
                  {ewalletNames
                    .filter((pair) =>
                      pair.name
                        .toLowerCase()
                        .includes(bankNameSearch.toLowerCase())
                    )
                    .map((n) => (
                      <ListItem disablePadding key={n.code}>
                        <ListItemButton
                          onClick={() => {
                            setBankCode(n.code);
                            setBankName(n.name);
                            setBankType('ewallet');
                            handleSelectBankModalClose();
                          }}
                        >
                          <ListItemText primary={n.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </TabPanel>
          </Box>

          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleSelectBankModalClose}
                sx={{
                  color: 'primary.mudahRed',
                  borderColor: 'primary.mudahRed',
                }}
              >
                Batal
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Withdrawal Confirm */}
      <Modal
        open={withdrawalConfirmModalOpen}
        onClose={handleWithdrawalConfirmModalClose}
      >
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 3,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Stack justifyContent="flex-end">
                <IconButton
                  aria-label="close"
                  sx={{ ml: 'auto' }}
                  onClick={handleCloseAllModals}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Konfirmasi Tarik Saldo
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Penarikan saldo instan (1-15 menit)
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Divider fullWidth />
            </Grid>

            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} md="auto">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Nominal
                </Typography>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body1">
                  {settlementAmount.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  })}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} md="auto">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Biaya Transfer Instan ⚡
                </Typography>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body1">
                  {settlementFee.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  })}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} md="auto">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Total Diterima
                </Typography>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body1">
                  {settlementFinal.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  })}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="space-between"
              xs={12}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} md="auto">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Tujuan
                </Typography>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body1">
                  {selectedSettlementAccount &&
                    getSettlementAccountText(
                      selectedSettlementAccount.bank_code.toUpperCase(),
                      selectedSettlementAccount.name
                    )}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Divider fullWidth />
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ borderRadius: 15 }}
                onClick={handleWithdrawalPINModalOpen}
              >
                Tarik Saldo
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: 'primary.mudahLightGrey',
                  borderRadius: 15,
                }}
                onClick={handleWithdrawalConfirmModalBack}
              >
                Kembali
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <BigFlipCopywriting />
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <InstantSettlementCopywriting />
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Withdrawal PIN */}
      <Modal
        open={withdrawalPINModalOpen}
        onClose={handleWithdrawalPINModalClose}
      >
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Stack justifyContent="flex-end">
                <IconButton
                  aria-label="close"
                  sx={{ ml: 'auto' }}
                  onClick={handleCloseAllModals}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Grid>

            <Grid
              item
              container
              justifyContent="align-left"
              xs={12}
              sx={{ mt: 4 }}
            >
              <Typography fontWeight={'bold'}>Masukkan PIN</Typography>
            </Grid>

            <Grid
              item
              container
              justifyContent="align-left"
              xs={12}
              sx={{ mt: 2 }}
            >
              {userPinError && (
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'primary.mudahRed' }}
                >
                  PIN salah! Masukkan kembali kode PIN.
                </Typography>
              )}
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <InputPin pinChangeHandler={userPinChangeHandler} />
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              {userPinLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: 15, mt: 4 }}
                  onClick={handleWithdrawalPINModalSend}
                  disabled={!userPinValid || userPinLoading || createSettlementLoading}
                >
                  Selanjutnya
                </Button>
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <BigFlipCopywriting />
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Withdrawal Success */}
      <Modal
        open={withdrawalSuccessModalOpen}
        onClose={handleWithdrawalSuccessModalClose}
      >
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Stack justifyContent="flex-end">
                <IconButton
                  aria-label="close"
                  sx={{ ml: 'auto' }}
                  onClick={handleCloseAllModals}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Box
                component="img"
                src={SettlementSuccessLogo}
                sx={{ width: '70%' }}
              />
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Penarikan Berhasil
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                Transaksi kamu selamat sampai tujuan ke{' '}
                {selectedSettlementAccount &&
                  selectedSettlementAccount.bank_code.toUpperCase()}{' '}
                untuk{' '}
                {selectedSettlementAccount && selectedSettlementAccount.name}.
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Button
                variant="contained"
                fullWidth
                sx={{ borderRadius: 15, mt: 4 }}
                onClick={handleWithdrawalSuccessModalClose}
              >
                Selesai
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <BigFlipCopywriting />
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Withdrawal Pending */}
      <Modal
        open={withdrawalPendingModalOpen}
        onClose={handleWithdrawalPendingModalClose}
      >
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Stack justifyContent="flex-end">
                <IconButton
                  aria-label="close"
                  sx={{ ml: 'auto' }}
                  onClick={handleCloseAllModals}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Box
                component="img"
                src={SettlementPendingLogo}
                sx={{ width: '70%' }}
              />
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Penarikan Diproses
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                Penarikan kamu sedang diproses ya, bisa di cek beberapa saat
                lagi.
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Button
                variant="contained"
                fullWidth
                sx={{ borderRadius: 15, mt: 4 }}
                onClick={handleWithdrawalPendingModalClose}
              >
                Selesai
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <BigFlipCopywriting />
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Withdrawal Failed */}
      <Modal
        open={withdrawalFailedModalOpen}
        onClose={handleWithdrawalFailedModalClose}
      >
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid item xs={12}>
            <Stack justifyContent="flex-end">
              <IconButton
                aria-label="close"
                sx={{ ml: 'auto' }}
                onClick={handleCloseAllModals}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Box
              component="img"
              src={SettlementFailedLogo}
              sx={{ width: '70%' }}
            />
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Penarikan Gagal
            </Typography>
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
              Transaksi kamu belum berhasil nih, bisa dicoba lagi ya.
            </Typography>
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Button
              variant="contained"
              fullWidth
              sx={{ borderRadius: 15, mt: 4 }}
              onClick={handleWithdrawalFailedModalClose}
            >
              Coba Lagi
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ mt: 4 }}>
            <BigFlipCopywriting />
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default Balance;
