import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { baseURLV2 } from '../../api';

export const useVerifyPin = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/account/pin/verify`,
        {
          pin: value.pin,
        },
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};

export const useUpdatePin = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/account/pin/update`,
        {
          old_pin: value.oldPin,
          new_pin: value.newPin,
        },
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
};
