import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

// import backend api
import { baseURLV2 } from '../../api';

export const useGetSmtAvailability = () => {
  return useQuery({
    queryKey: ['smtAvailability'],
    staleTime: 5000,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/settlement/available`,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data.available;
    },
  });
};

export const useGetBalance = () => {
  return useQuery({
    queryKey: ['balance'],
    staleTime: 5000,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/settlement/balance/get`,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data.balance;
    },
  });
};

export const useGetSmtAccounts = () => {
  return useQuery({
    queryKey: ['smtAccounts'],
    staleTime: Infinity,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseURLV2}/teras/v1/settlement/accounts/get`,
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data.accounts;
    },
  });
};

export const useCreateSettlementAccount = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/settlement/accounts/create`,
        {
          name: value.name,
          account_number: value.accountNumber,
          bank_code: value.bankCode,
          bank_name: value.bankName,
          bank_type: value.bankType,
        },
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
}

export const useDeleteSettlementAccount = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/settlement/accounts/delete`,
        {
          name: value.name,
          account_number: value.accountNumber,
          bank_code: value.bankCode,
        },
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
}

export const useCreateSettlement = () => {
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(
        `${baseURLV2}/teras/v1/settlement/create`,
        {
          amount: value.amount,
          settlement_account: value.settlementAccount,
        },
        {
          timeout: 7000,
          withCredentials: true,
        }
      );
      return data;
    },
  });
}
