import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import mui components
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@mui/material';

// import page components
import InputPin from '../InputPin';

// import helper functions
import { cyrb53 } from '../../../helper';

const LoginPin = () => {
  const [pinValid, setPinValid] = useState(false);
  const [pinNumber, setPinNumber] = useState('');
  const [pinError, setPinError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { verifyPIN } = useAuth();

  const pinReg = new RegExp('^[0-9]{6}$');

  const pinChangeHandler = (inputNumber) => {
    setPinNumber(inputNumber);
    pinReg.test(pinNumber) ? setPinValid(true) : setPinValid(false);
  };

  const formHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let hashedPin = cyrb53(pinNumber).toString();
    const result = await verifyPIN(hashedPin);
    if (result.success) {
      setIsLoading(false);
      navigate('/');
    }
    setPinError(true);
    setIsLoading(false);
  };

  return (
    <Box component="form" onSubmit={formHandler} sx={{ width: 1, height: 1 }}>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="align-left" xs={12} sx={{ mt: 4 }}>
          <Typography>Masukkan PIN</Typography>
        </Grid>

        <Grid item container justifyContent="align-left" xs={12}>
          {pinError && (
            <Typography variant="subtitle2" sx={{ color: 'primary.mudahRed' }}>
              PIN salah! Masukkan kembali kode PIN.
            </Typography>
          )}
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 2 }}>
          <InputPin pinChangeHandler={pinChangeHandler} />
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
              textAlign: 'center',
            }}
          >
            Lupa PIN?{' '}
            <Link
              color="inherit"
              href="https://api.whatsapp.com/send?phone=6281318824067"
              target="_blank"
            >
              Hubungi kami.
            </Link>
            {/* <Link color="inherit" href="/auth/pin/reset">
              Klik disini.
            </Link> */}
          </Typography>
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!pinValid || isLoading}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Masuk
              </Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPin;
