import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import page components
import InputOTP from '../InputOTP';

const LoginOTP = ({ handleNext, userPhoneNumber, otpChannel }) => {
  const [otpValid, setOtpValid] = useState(false);
  const [otpNumber, setOtpNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { verifyOTP, resendOTP } = useAuth();
  const navigate = useNavigate();

  const otpReg = new RegExp('^[0-9]{6}$');

  const redactedPhoneNumber = userPhoneNumber.replace(
    userPhoneNumber.substring(5, userPhoneNumber.length - 2),
    '*'.repeat(userPhoneNumber.length - 7)
  );

  const otpChangeHandler = (pinNumber) => {
    setOtpNumber(pinNumber);
    otpReg.test(otpNumber) ? setOtpValid(true) : setOtpValid(false);
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();

    const phoneWithCountryCode =
      '+62' + userPhoneNumber.slice(1, userPhoneNumber.length);

    await resendOTP(phoneWithCountryCode, otpChannel);

    alert('Kode OTP telah dikirimkan ulang melalui sms ke nomor anda.');
  };

  const formHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await verifyOTP(otpNumber);
    if (!result.success) {
      if (result?.reason === 'logged_out') {
        alert('Kode OTP telah kadaluarsa. Silakan masuk kembali.');
        navigate('/login');
      }
      alert('Kode OTP salah! Silakan coba lagi.');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      handleNext();
    }
  };

  return (
    <Box component="form" onSubmit={formHandler} sx={{ width: 1, height: 1 }}>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="align-left" xs={12} sx={{ mt: 4 }}>
          <Typography>Masukan kode OTP</Typography>
        </Grid>
        <Grid item container justifyContent="align-left" xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ color: 'primary.mudahDarkGrey' }}
          >
            Kode OTP telah dikirimkan melalui sms ke {redactedPhoneNumber}
          </Typography>
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 2 }}>
          <InputOTP pinChangeHandler={otpChangeHandler} />
        </Grid>

        <Grid item container justifyContent="center" xs={12}>
          <Typography
            variant="subtitle2"
            onClick={handleResendOtp}
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
              mt: 1,
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            Kirim ulang kode OTP?
          </Typography>
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!otpValid || isLoading}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Selanjutnya
              </Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginOTP;
