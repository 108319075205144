import React, { useState } from 'react';

// import auth components
import useAuth from '../../../hooks/useAuth';

// import mui components
import { Button, Box, Grid, Typography } from '@mui/material';

// import page components
import InputPin from '../InputPin';

// import helper functions
import { cyrb53 } from '../../../helper';

const RegisterOTP = ({ handleNext, bizType }) => {
  const [pinValid, setPinValid] = useState(false);
  const [pinNumber, setPinNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { registerFinalise } = useAuth();

  const pinReg = new RegExp('^[0-9]{6}$');

  const pinChangeHandler = (inputNumber) => {
    setPinNumber(inputNumber);
    pinReg.test(pinNumber) ? setPinValid(true) : setPinValid(false);
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const hashedPin = cyrb53(pinNumber);
    const stringHashedPin = hashedPin.toString();

    const res = await registerFinalise(stringHashedPin, bizType);
    if (!res.success) {
      setIsLoading(false);
      alert('Terjadi kesalahan saat mendaftarkan akun Anda. Silakan coba lagi.');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      handleNext();
    }
  };

  return (
    <Box component="form" onSubmit={formHandler}>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography>Buat kode PIN</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
            }}
          >
            Digunakan untuk masuk aplikasi dan keamanan bertransaksi
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={12} sx={{ mt: 2 }}>
          <Grid container justifyContent="center" xs={12}>
            <InputPin pinChangeHandler={pinChangeHandler} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!pinValid || isLoading}
          >
            <Typography
              sx={{ color: 'primary.mudahWhite' }}
            >
              Atur PIN
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterOTP;
